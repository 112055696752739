define("discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard-field", ["exports", "@ember/object", "discourse/plugins/discourse-custom-wizard/discourse/mixins/valid-state", "discourse-common/utils/decorators", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard"], function (_exports, _object, _validState, _decorators, _wizard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const StandardFieldValidation = ["text", "number", "textarea", "dropdown", "tag", "image", "user_selector", "text_only", "composer", "category", "group", "date", "time", "date_time"];
  var _default = _exports.default = _object.default.extend(_validState.default, dt7948.p({
    id: null,
    type: null,
    value: null,
    required: null,
    i18nKey(wizardId, stepId, id) {
      return `${wizardId}.${stepId}.${id}`;
    },
    translatedLabel(i18nKey, label) {
      return (0, _wizard.translationOrText)(`${i18nKey}.label`, label);
    },
    translatedPlaceholder(i18nKey, placeholder) {
      return (0, _wizard.translationOrText)(`${i18nKey}.placeholder`, placeholder);
    },
    translatedDescription(i18nKey, description) {
      return (0, _wizard.translationOrText)(`${i18nKey}.description`, description);
    },
    check() {
      if (this.customCheck) {
        return this.customCheck();
      }
      let valid = this.valid;
      if (!this.required) {
        this.setValid(true);
        return true;
      }
      const val = this.get("value");
      const type = this.get("type");
      if (type === "checkbox") {
        valid = val;
      } else if (type === "upload") {
        valid = val && val.id > 0;
      } else if (StandardFieldValidation.indexOf(type) > -1) {
        valid = val && val.toString().length > 0;
      } else if (type === "url") {
        valid = true;
      }
      this.setValid(Boolean(valid));
      return valid;
    }
  }, [["method", "i18nKey", [(0, _decorators.default)("wizardId", "stepId", "id")]], ["method", "translatedLabel", [(0, _decorators.default)("i18nKey", "label")]], ["method", "translatedPlaceholder", [(0, _decorators.default)("i18nKey", "placeholder")]], ["method", "translatedDescription", [(0, _decorators.default)("i18nKey", "description")]]]));
});